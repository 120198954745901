import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate, Link } from 'react-router-dom';
import config from '../utils/config';

export default function NavbarCom() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/products`); 
        if (response.ok) {
          const data = await response.json();
          setProducts(data.products); 
        } else {
          console.error('Ürünler alınırken bir hata oluştu.');
        }
      } catch (error) {
        console.error('Sunucuya bağlanılamadı:', error);
      }
    };

    fetchProducts();
  }, [navigate]);

  

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
          <img className='logo' src="https://i.ibb.co/1qfFX3D/1.png" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Anasayfa</Nav.Link>
            <Nav.Link as={Link} to="/about">Hakkımızda</Nav.Link>
            <Nav.Link as={Link} to="/contact">İletişim</Nav.Link>

            <NavDropdown title="Ürünler" id="basic-nav-dropdown">
              {products.map((product) => (
                <NavDropdown.Item key={product._id} as={Link} to={`/productDetail/${product._id}`}>
                  {product.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
