import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormControl, FormLabel, Input, Button, VStack } from '@chakra-ui/react';
import config from '../../utils/config';

export default function AddProducts() {
  const [links, setLinks] = useState([]);
  const [name, setProductName] = useState('');
  const [description, setProductDescription] = useState('');
  const [message, setMessage] = useState('');

  const handleLinkChange = (e, index) => {
    const updatedLinks = [...links];
    updatedLinks[index] = e.target.value;
    setLinks(updatedLinks);
  };

  const addInput = () => {
    setLinks([...links, '']);
  };

  const removeInput = (index) => {
    setLinks(links.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      links: links,
      name: name,
      description: description
    };

    // Debug: Gönderilen verileri kontrol et
    console.log('Sending Data:', data);

    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${config.apiUrl}/addProduct`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setLinks([]);
        setProductName('');
        setProductDescription('');
        setMessage('Ürün başarıyla eklendi!');
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || 'Ürün eklenirken bir sorun oluştu');
      }
    } catch (err) {
      setMessage('Sunucuya bağlanırken bir sorun oluştu.');
    }
  };

  return (
    <div>
      <Helmet>
        <title>Ürün Ekle</title>
        <meta name="description" content="Ürün Ekle" />
        <meta name="keywords" content="Aydın Plastik" />
        <meta name="author" content="Lion Tech" />
      </Helmet>

      <h1 className='text-center'>Ürün Ekle</h1>

      <div className="container">
        <form onSubmit={handleSubmit}>
          {message && <p style={{ color: 'red' }}>{message}</p>}

          <FormControl>
            <FormLabel>Görsel URL'leri</FormLabel>
            <VStack spacing={4} align="stretch">
              {links.map((link, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <Input
                    type='text'
                    placeholder='Görsel URL'
                    name='links'
                    onChange={(e) => handleLinkChange(e, index)}
                    value={link}
                    required
                  />
                  <Button onClick={() => removeInput(index)}>Sil</Button>
                </div>
              ))}
              <Button onClick={addInput}>Yeni Link</Button>
            </VStack>
          </FormControl>

          <FormControl>
            <FormLabel>Ürün Adı</FormLabel>
            <Input
              type='text'
              placeholder='Ürün Adı'
              name='name'
              value={name}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
          </FormControl>

          <FormControl>
            <FormLabel>Ürün Açıklaması</FormLabel>
            <Input
              type='text'
              placeholder='Ürün Açıklaması'
              name='description'
              value={description}
              onChange={(e) => setProductDescription(e.target.value)}
              required
            />
          </FormControl>

          <Button type="submit">Ürün Ekle</Button>
        </form>
      </div>
    </div>
  );
}
