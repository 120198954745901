import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, FormControl, FormLabel, Input, Textarea, useToast } from '@chakra-ui/react';
import config from '../../utils/config';

export default function Contact() {
  const [formData, setFormData] = useState({
    ad: '',
    soyad: '',
    email: '',
    telefon: '',
    mesaj: ''
  });

  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${config.apiUrl}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast({
          title: "Mesajınız gönderildi.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFormData({ ad: '', soyad: '', email: '', telefon: '', mesaj: '' });
      } else {
        toast({
          title: "Mesaj gönderilemedi.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <Helmet>
        <title>İletişim</title>
        <meta name="description" content="İletişim" />
        <meta name="keywords" content="Aydın Plastik" />
        <meta name="author" content="Lion Tech" />
      </Helmet>

      <Box className="container" maxW="md" mx="auto" mt={8} p={4} borderWidth={1} borderRadius="lg">
        <form onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel>Ad</FormLabel>
            <Input 
              type="text" 
              name="ad" 
              value={formData.ad} 
              onChange={handleChange} 
              placeholder="Adınızı girin" 
            />
          </FormControl>

          <FormControl mt={4} isRequired>
            <FormLabel>Soyad</FormLabel>
            <Input 
              type="text" 
              name="soyad" 
              value={formData.soyad} 
              onChange={handleChange} 
              placeholder="Soyadınızı girin" 
            />
          </FormControl>

          <FormControl mt={4} isRequired>
            <FormLabel>Email</FormLabel>
            <Input 
              type="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              placeholder="Email adresinizi girin" 
            />
          </FormControl>

          <FormControl mt={4} isRequired>
            <FormLabel>Telefon</FormLabel>
            <Input 
              type="tel" 
              name="telefon" 
              value={formData.telefon} 
              onChange={handleChange} 
              placeholder="Telefon numaranızı girin" 
            />
          </FormControl>

          <FormControl mt={4} isRequired>
            <FormLabel>Mesaj</FormLabel>
            <Textarea 
              name="mesaj" 
              value={formData.mesaj} 
              onChange={handleChange} 
              placeholder="Mesajınızı girin" 
            />
          </FormControl>

          <Button mt={4} colorScheme="blue" type="submit">Gönder</Button>
        </form>
      </Box>
    </div>
  );
}
