import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Input, Textarea, VStack } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../../utils/config';

export default function EditProductDetail() {
  const { productId } = useParams(); 
  const [product, setProduct] = useState({ name: '', description: '', images: [] });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/products/${productId}`);
        const responseData = await response.text(); 
        console.log('API response:', responseData); 
  
        const data = JSON.parse(responseData); 
        setProduct(data.product);
      } catch (error) {
        console.error('Ürün bilgileri alınamadı:', error);
      }
    };
  
    fetchProduct();
  }, [productId]);

  const handleSave = async () => {
    try {
      const response = await fetch(`http://92.205.229.14:5050/updateProduct`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productId, ...product }), 
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Ürün başarıyla güncellendi:', result.message);
        navigate('/editProduct'); 
      } else {
        console.error('Ürün güncellenemedi:', result.message);
      }
    } catch (error) {
      console.error('Ürün güncellenirken bir hata oluştu:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleImageChange = (index, value) => {
    const updatedImages = [...product.images];
    updatedImages[index] = value;
    setProduct((prevProduct) => ({
      ...prevProduct,
      images: updatedImages,
    }));
  };

  const handleAddImage = () => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      images: [...prevProduct.images, ''],
    }));
  };

  return (
    <div>
      <Helmet>
        <title>Ürün Düzenleme Sayfası</title>
        <meta name="description" content="Ürün Düzenleme Sayfası" />
        <meta name="keywords" content="Aydın Plastik" />
        <meta name="author" content="Lion Tech" />
      </Helmet>

      <div className="container">
        <h1>Ürünü Düzenle</h1>
        <Input
          placeholder="Ürün Adı"
          value={product.name}
          name="name"
          onChange={handleChange}
          mb="4"
        />
        <Textarea
          placeholder="Ürün Açıklaması"
          value={product.description}
          name="description"
          onChange={handleChange}
          mb="4"
        />
        
        <VStack spacing={4}>
          {product.images.map((link, index) => (
            <Input
              key={index}
              placeholder={`Görsel Link ${index + 1}`}
              value={link}
              onChange={(e) => handleImageChange(index, e.target.value)}
            />
          ))}
        </VStack>
        <Button mt="4" onClick={handleAddImage}>
          Yeni Görsel Link Ekle
        </Button>
        <Button onClick={handleSave} colorScheme="blue" mt="4">
          Kaydet
        </Button>
      </div>
    </div>
  );
}
