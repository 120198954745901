import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';


//Components
import NavBar from './components/Navbar';
import Footer from './components/Footer';


//Pages
import Home from './components/pages/Home';
import About from './components/pages/About';
import Products from './components/pages/Products';
import Contact from './components/pages/Contact';
import ProductDetail from './components/adminPages/ProductDetail';
import ConfidentialityAgree from './components/pages/ConfidentialityAgree';
import TermsOfUse from './components/pages/TermsOfUse';


//Admin Pages
import Admin from './components/adminPages/Admin';
import AddProduct from './components/adminPages/AddProducts';
import EditProduct from './components/adminPages/EditProducts';
import Contacts from './components/adminPages/Contacts';
import Login from './components/adminPages/Login';
import Register from './components/adminPages/Register';
import EditProductDetail from './components/adminPages/EditProductDetail';





import './App.css';

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <ChakraProvider>
          <Router>
            <NavBar/>
            <Routes>
              <Route element={<Home />} path="/"/>
              <Route element={<About />} path="/about"/>
              <Route element={<Products />} path="/products"/>
              <Route element={<Contact />} path="/contact"/>

              <Route element={<Admin />} path="/admin"/>
              <Route element={<AddProduct />} path="/addProduct"/>
              <Route element={<EditProduct />} path="/editProduct"/>
              <Route element={<Contacts />} path="/contacts"/>
              <Route element={<Login />} path="/login"/>
              <Route element={<Register />} path="/register"/>
              <Route element={<EditProductDetail />} path="/edit-product/:productId"/>
              <Route element={<ProductDetail />} path="/productDetail/:productId"/>
              <Route element={<ConfidentialityAgree />} path="/gizlilik-sozlesmesi"/>
              <Route element={<TermsOfUse />} path="/kullanim-sartlari"/>







            </Routes>
            <Footer/>
          </Router>
         
        </ChakraProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
