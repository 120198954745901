import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CardHeader, CardBody, CardFooter, Heading, Text, Button } from '@chakra-ui/react'

export default function Admin() {
  return (
    <div>
      <Helmet>
        <title>Yönetici</title>
        <meta name="description" content="Yönetici" />
        <meta name="keywords" content="Aydın Plastik" />
        <meta name="author" content="Lion Tech" />
      </Helmet>

      <h1 className='text-center'>Yönetici Sayfası</h1>

      <div className="container">
        <Card
          align='center'
          marginTop={10}
          border='2px'
          borderColor='gray.300' 
          borderRadius='md' 
          boxShadow='md' 
        >
          <CardHeader>
            <Heading size='md'>Ürün Ekle</Heading>
          </CardHeader>
          <CardBody>
            <Text>Ekleyeceğiniz ürünler "Anasayfa"da ve "Ürün Detay Sayfa"sında bulunacak.</Text>
          </CardBody>
          <CardFooter>
            <a href="/addProduct">
              <Button colorScheme='blue'>Ekle</Button>
            </a>
            
          </CardFooter>
        </Card>

        <Card
          align='center'
          marginTop={10}
          border='2px'
          borderColor='gray.300' 
          borderRadius='md' 
          boxShadow='md' 
        >
          <CardHeader>
            <Heading size='md'>Ürün Düzenle</Heading>
          </CardHeader>
          <CardBody>
            <Text>Daha önceden eklediğiniz ürünleri listeleyebilir ya da düzenleyebilirsiniz.</Text>
          </CardBody>
          <CardFooter>
            <a href="/editProduct">
              <Button colorScheme='blue'>Listele/Düzenle</Button>
            </a>
            
          </CardFooter>
        </Card>

        <Card
          align='center'
          marginTop={10}
          border='2px'
          borderColor='gray.300' 
          borderRadius='md' 
          boxShadow='md' 
        >
          <CardHeader>
            <Heading size='md'>Gelen İletişim Formları</Heading>
          </CardHeader>
          <CardBody>
            <Text>Müşterilerin gönderdiği iletişim formlarını görüntüleyebilir ve müşteri iletişim bilgilerine ulaşabilirsiniz.</Text>
          </CardBody>
          <CardFooter>
            <a href="/contacts">
              <Button colorScheme='blue'>Görüntüle</Button>
            </a>
            
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}
