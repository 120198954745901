import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Heading, Text, VStack, Spinner, Alert, AlertIcon } from '@chakra-ui/react';
import config from '../../utils/config';

export default function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/contacts`);
        if (!response.ok) {
          throw new Error('Veriler getirilemedi');
        }
        const data = await response.json();
        setContacts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  return (
    <div>
      <Helmet>
        <title>İletişim Formları</title>
        <meta name="description" content="İletişim Formları" />
        <meta name="keywords" content="Aydın Plastik" />
        <meta name="author" content="Lion Tech" />
      </Helmet>

      <Box className="container" maxW="md" mx="auto" mt={8} p={4} borderWidth={1} borderRadius="lg">
        <Heading as="h2" size="lg" mb={6}>İletişim Formları</Heading>

        {loading && <Spinner size="xl" />}
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}

        {!loading && !error && contacts.length > 0 && (
          <VStack spacing={4} align="stretch">
            {contacts.map((contact) => (
              <Box key={contact._id} p={4} borderWidth={1} borderRadius="md">
                <Text><strong>Ad:</strong> {contact.ad}</Text>
                <Text><strong>Soyad:</strong> {contact.soyad}</Text>
                <Text><strong>Email:</strong> {contact.email}</Text>
                <Text><strong>Telefon:</strong> {contact.telefon}</Text>
                <Text><strong>Mesaj:</strong> {contact.mesaj}</Text>
              </Box>
            ))}
          </VStack>
        )}

        {!loading && !error && contacts.length === 0 && (
          <Text>Henüz hiçbir iletişim formu yok.</Text>
        )}
      </Box>
    </div>
  );
}
