import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';
import { Button, Table } from '@chakra-ui/react'; 
import config from '../../utils/config';

export default function EditProducts() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Ürünleri API'den çekmek için bir fetch isteği yapın
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/products`); // Ürünler API URL'sini buraya koyun
        const data = await response.json();
        setProducts(data.products); // API'den gelen ürünleri state'e set edin
      } catch (error) {
        console.error('Ürünler alınırken bir hata oluştu:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (productId) => {
    console.log(`Sil: ${productId}`);
  
    try {
      const response = await fetch(`${config.apiUrl}/deleteProduct`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json', 
        },
        body: JSON.stringify({ productid: productId }), 
      });
  
      const result = await response.json(); 
  
      if (response.ok) {
       
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product._id !== productId)
        );
        console.log(result.message); 
      } else {
        console.error(result.message); 
      }
    } catch (error) {
      console.error('Ürün Silinemedi:', error); 
    }
  };

  const handleEdit = (productId) => {
    console.log(`Düzenle: ${productId}`);
    navigate(`/edit-product/${productId}`);
  };

  return (
    <div>
      <Helmet>
        <title>Ürün Düzenle</title>
        <meta name="description" content="Ürün Düzenle" />
        <meta name="keywords" content="Aydın Plastik" />
        <meta name="author" content="Lion Tech" />
      </Helmet>
      <div className="container">
        <h1>Ürünleri Düzenle</h1>

        <Table variant="simple">
          <thead>
            <tr>
              <th>Ürün Adı</th>
              <th>Ürün Açıklaması</th>
              <th>Görseller</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id}>
                <td>{product.name}</td>
                <td>{product.description}</td>
                <td>{product.images.length} görsel</td>
                <td>
                  <Button onClick={() => handleEdit(product._id)} colorScheme="blue" mr="2">
                    Düzenle
                  </Button>
                  <Button onClick={() => handleDelete(product._id)} colorScheme="red">
                    Sil
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

    </div>
  );
}
