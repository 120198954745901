import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function About() {
  return (
    <div>
      <Helmet>
        <title>Hakkımızda</title>
        <meta name="description" content="Hakkımızda" />
        <meta name="keywords" content="Aydın Plastik" />
        <meta name="author" content="Lion Tech" />
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <h1 className='text-center'>Hakkımızda</h1>
            <p>Aydın Bey Plastik, Türkiye'nin plastik sektöründeki önde gelen isimlerinden biridir. Mersin merkezli firmamız, polietilen ve polipropilen bazlı geniş ürün yelpazesi ile müşterilerine yüksek kaliteli plastik hammadde tedariği sağlamaktadır. Yılların getirdiği deneyim ve uzmanlıkla, çeşitli endüstriler için ihtiyaç duyulan plastik çözümlerini sunmaktan gurur duyuyoruz.</p>
            <p>Ürün portföyümüz, farklı özelliklere sahip çeşitli polietilen ve polipropilen kompoundları ile geniş bir yelpazeyi kapsar. Özellikle Polietilen Kompound ürünlerimiz, MFI (Akış İndeksi) değerlerine göre kategorize edilmiştir ve aşağıdaki gibi sunulmaktadır:</p>
            <ul>
              <li>Polietilen Kompound 1.2 MFI: Yüksek akışkanlık özelliği ile çeşitli üretim süreçlerinde kullanıma uygun, yüksek performanslı bir malzemedir.</li>
              <li>Polietilen Kompound 0.5 MFI: Daha düşük akışkanlık, yüksek dayanıklılık ve uzun ömür sağlamak için ideal bir seçimdir.</li>
              <li>Polietilen Kompound 0.2 MFI: Son derece düşük akışkanlık özellikleri sayesinde, zorlu uygulama alanları için uygundur.</li>
              <li>Polietilen Kompound 1.6 MFI: Yüksek akışkanlık ve işlem kolaylığı sunar, çeşitli endüstriyel uygulamalar için tercih edilir.</li>
              <li>Polietilen Kompound 0.8 MFI: Dengeli akışkanlık ve dayanıklılık özellikleri ile farklı üretim ihtiyaçlarını karşılar.</li>
            </ul>
            <p>Polipropilen ürünlerimiz, kalite ve dayanıklılığı bir araya getirerek, çeşitli renk seçenekleri ile sunulmaktadır:</p>
            <ul>
              <li>Polipropilen - Siyah: Şık ve estetik bir görünüm sunarken, endüstriyel dayanıklılığıyla da dikkat çeker.</li>
              <li>Polipropilen - Gri: Nötr renk tonu ile geniş kullanım alanlarına sahip, sağlam ve uzun ömürlü bir malzemedir.</li>
            </ul>
            <b>Ayrıca, GPPS (General Purpose Polystyrene) ürünlerimiz, çeşitli renk ve özelliklerde mevcuttur:</b>
            <ul>
              <li>GPPS Recycled Kristal Şeffaf: Şeffaf yapısı sayesinde içeriğin görünürlüğünü sağlar ve geri dönüştürülmüş materyal ile çevre dostu bir seçenektir.</li>
              <li>GPPS Yumuşak: Esneklik ve dayanıklılığı bir araya getirir, çeşitli uygulama alanları için uygundur.</li>
              <li>GPPS Sarı: Canlı renk tonu ile estetik ve dikkat çekici bir görünüm sunar.</li>
              <li>GPPS Mavi: Güzel bir mavi tonuyla, hem estetik hem de fonksiyonel kullanım imkanı sağlar.</li>
            </ul>
            <p>Müşterilerimize en iyi hizmeti sunmak ve onların ihtiyaçlarına en uygun çözümleri sağlamak için sürekli olarak ürün yelpazemizi genişletiyor ve kalite standartlarını yükseltiyoruz. Mersin’deki merkezimizden, Türkiye’nin dört bir yanına ürün gönderebilme kapasitesine sahibiz. Ürünlerimizin kalitesini ve müşteri memnuniyetini ön planda tutarak, her zaman güvenilir ve etkili çözümler sunmayı hedefliyoruz.</p>
            <p>Aydın Bey Plastik olarak, plastik endüstrisindeki geniş deneyimimiz ve müşteri odaklı yaklaşımımızla, sektördeki en iyi hizmeti sunmaya devam ediyoruz. Bizimle iletişime geçerek, ihtiyaçlarınıza en uygun plastik hammadde çözümlerini keşfedebilirsiniz.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
