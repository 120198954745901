import React,{useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import config from '../../utils/config';

export default function Register() {
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [error,setError] = useState('');
    const [success,setSuccess] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async(e)=>{
        e.preventDefault();

        try{
            const response = await fetch(`${config.apiUrl}/register`,{
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body:JSON.stringify({email,password}),
            });
            if(response.ok){
                const data = await response.json();
                setSuccess(data.message);
                setError('');
                setEmail('');
                setPassword('');
                localStorage.setItem('token',data.token); // JWT İçin
                navigate('/login');
            }else{
                const errorData = await response.json();
                setError(errorData.message || "Kayıt Sırasında Bir Hata Oluştu");
                setSuccess('');
            }
        } catch(error){
            setError('Sunucuya Bağlanılamadı');
            setSuccess('')
        }
    };
  
  return (
    <div className='container'>
       <Helmet>
        <title>Admin Kayıt</title>
        <meta name="description" content="Admin Kayıt" />
        <meta name="keywords" content="Aydın Plastik" />
        <meta name="author" content="Lion Tech" />
      </Helmet>

      <div className="container">
        <form onSubmit={handleSubmit}>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'green' }}>{success}</p>}
            <label htmlFor="email">Email:</label>
            <input
                type="email"
                id='email'
                name='email'
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                required
                placeholder='Mail Adresi'
              
              />

            <label htmlFor="password">Şifre:</label>
            <input 
                type="password" 
                id='password'
                name='password'
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
                required
                placeholder='Şifre'
            />

            <input type="submit" value="Kaydol" />

        </form>
      </div>
    </div>
  );
}
