import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import config from '../../utils/config';

import { 
    Container, 
    FormControl, 
    FormLabel, 
    Input, 
    Button, 
    Box, 
    Text, 
} from '@chakra-ui/react';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${config.apiUrl}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });
            if (response.ok) {
              console.log('Giriş Başarılı')
                const data = await response.json();
                localStorage.setItem('token', data.token); // JWT için
                setError('');
                navigate('/admin', { replace: true }); 
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Giriş sırasında bir hata oluştu.');
            }
        } catch (err) {
            setError('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.');
        }
    };

    return (
        <Container centerContent   h='100vh' style={{display:'flex',justifyContent:'center'}}>
            <Helmet>
                <title>Giriş Yap</title>
                <meta name="description" content="Giriş Yap" />
                <meta name="keywords" content="Aydın Plastik" />
                <meta name="author" content="Lion Tech" />
            </Helmet>
            <h1>Giriş Yap</h1>
            <Box 
                p={5} 
                shadow="md" 
                borderWidth="1px" 
                borderRadius="md" 
                w="100%" 
            >
                <form onSubmit={handleSubmit}>
                    <FormControl id="email" mb={4} isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input 
                            type="email" 
                            id="email" 
                            value={email}
                            name='email'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormControl>
                    
                    <FormControl id="password" mb={4} isRequired>
                        <FormLabel>Şifre</FormLabel>
                        <Input 
                            type="password" 
                            id="password" 
                            value={password}
                            name='password'
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormControl>
                    
                    <Button 
                        colorScheme="teal" 
                        type="submit"
                        w="full"
                    >
                        Giriş Yap
                    </Button>

                    {error && (
                        <Text color="red.500" mt={4}>
                            {error}
                        </Text>
                    )}
                </form>
            </Box>
        </Container>
    );
}
