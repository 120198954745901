import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import config from '../../utils/config';

export default function ProductDetail() {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await fetch(`${config.apiUrl}/products/${productId}`);
                if (response.ok) {
                    const data = await response.json();
                    setProduct(data.product);
                } else {
                    console.error('Ürün detayları alınırken bir hata oluştu.');
                }
            } catch (error) {
                console.error('Sunucuya bağlanılamadı:', error);
            }
        };

        fetchProduct();
    }, [productId]);

    if (!product) {
        return <div>Yükleniyor...</div>;
    }

    return (
        <div>
            <Helmet>
                <title>{product.name}</title>
                <meta name="description" content={product.description} />
                <meta name="keywords" content="Aydın Plastik, Plastik Hammadde" />
                <meta name="author" content="Lion Tech" />
            </Helmet>
            <div id='productDetail' className="container p-5" style={{height:'100vh'}}>
                <h1 className='text-center'>{product.name}</h1>
               
                <div className='productDetailImg'>
                    {product.images.map((image, index) => (
                        <img key={index} src={image} alt={`Ürün görseli ${index + 1}`} />
                    ))}
                </div>
            </div>

        </div>
    );
}
