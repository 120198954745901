import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { useNavigate, Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap'; 
import config from '../utils/config';

export default function Footer() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/logout`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        localStorage.removeItem('token'); 
        setIsLoggedIn(false); 
        navigate('/login'); 
      } else {
        console.error('Çıkış yaparken hata oluştu.');
      }
    } catch (error) {
      console.error('Sunucuya bağlanılamadı:', error);
    }
  };

  return (
    <footer>
      <ul>
        <h5>İletişim</h5>
        <li><small>Aydınlıkevler Mahallesi/YENİŞEHİR/MERSİN </small></li>
        <li> <b>Telefon:+90(533) 629 44 33</b> </li>
        <li> <b>E-posta:agca33@hotmail.com</b> </li>
      </ul>

      <ul>
        <h5>Bağlantılar</h5>
        <li>
          <a href='/kullanim-sartlari'>Kullanım Şartları</a>
        </li>
        <li>
          <a href='/gizlilik-sozlesmesi'>Gizlilik Sözleşmesi</a>
        </li>
        <li>
          <a href='/contact'>İletişim Formu</a>
        </li>
        <li>
          {isLoggedIn ? (
            <>
              <Nav.Link as={Link} to='/admin'>Yönetici</Nav.Link>
              <Nav.Link onClick={handleLogout} style={{ cursor: 'pointer' }}>Çıkış Yap</Nav.Link>
            </>
          ) : (
            <Nav.Link as={Link} to="/login">Giriş Yap</Nav.Link>
          )}
        </li>
      </ul>

      <ul>
        <h5>Sosyal Medya</h5>
        <li>
          <a href='https://www.instagram.com' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </li>
        <li>
          <a href='https://www.twitter.com' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
        </li>
      </ul>
    </footer>
  );
}
