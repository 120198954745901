import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Helmet } from 'react-helmet-async';
import config from '../../utils/config';

export default function Home() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/products`); 
        const data = await response.json();
        setProducts(data.products); 
      } catch (error) {
        console.error('Ürünler alınırken bir hata oluştu:', error);
      }
    };

    fetchProducts();
  }, []);
  const limitedProducts = products.slice(0, 6);
  return (
    <div className='container'>
      <Helmet>
        <title>Aydın Bey Plastik</title>
        <meta name="description" content="Home" />
        <meta name="keywords" content="Aydın Plastik , Plastik Hammadde" />
        <meta name="author" content="Lion Tech" />
      </Helmet>

      <div className="homeTitle">
        <h1 className='text-center'>Aydın Bey Plastik</h1>
        <h2 className='text-center'>Plastik Hammadde Tedariği</h2>
      </div>
      <hr />
      <div className="productTitle">
        <h3 className='text-center'>Ürünlerimiz</h3>
      </div>
      <div className="products p-5" data-aos="fade-down" >
      <div className="row justify-content-center">
        {limitedProducts.map((product, productIndex) => (
          product.images && product.images.length > 0 && (
            <div key={productIndex} className="product-carousel">
              <div className="col-lg-4 col-sm-3">
                <h5 className='text-center'>{product.name}</h5>
                <Carousel data-bs-theme="dark">
                  {product.images.map((image, imageIndex) => (
                    <Carousel.Item key={`${productIndex}-${imageIndex}`}>
                      <img
                        className="d-block w-100"
                        src={image}
                        alt={`Slide ${productIndex}-${imageIndex}`}
                      />
                      {imageIndex === 0 && (
                        <Carousel.Caption>
                          <p>{product.description}</p>
                        </Carousel.Caption>
                      )}
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
          )
        ))}
      </div>
    </div>
      <hr />
      <div className="productTitle">
        <h3 className='text-center'>Kullanım Alanları</h3>
      </div>
      <div className="areasOfUse p-5" data-aos="fade-down">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="areasTitle">
                <h5 className='text-center'>Poliproplen ve Kullanım Alanları</h5>
              </div>
              <p>
              Polipropilen, hafifliği, yüksek dayanıklılığı ve kimyasal direnç özellikleri sayesinde geniş bir kullanım alanına sahip bir termoplastiktir. Gıda ambalajlarından tıbbi ekipmanlara kadar birçok sektörde kullanılır. Gıda endüstrisinde, polipropilen, ambalajlama ve kaplama malzemeleri olarak tercih edilirken, tıbbi sektörde steril ürünlerin üretiminde önemli bir rol oynar. Ayrıca, otomotiv endüstrisinde iç mekan parçaları, kaputlar ve diğer bileşenler için kullanılır. İnşaat sektöründe, polipropilen, borular, yalıtım malzemeleri ve halı altı keçe gibi ürünlerde bulunur. Ayrıca, tekstil endüstrisinde halı, giysi ve döşemelik kumaşların üretiminde kullanılır. Polipropilenin avantajları arasında yüksek sıcaklık dayanımı, düşük nem emilimi ve uzun ömürlülüğü bulunur, bu da onu endüstriyel ve günlük kullanımda çok yönlü bir malzeme yapar.
              </p>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="areasTitle">
                <h5 className='text-center'>Polietilen ve Kullanım Alanları</h5>
              </div>
              <p>
                Polietilen, düşük maliyeti, hafifliği ve dayanıklılığı sayesinde geniş bir kullanım yelpazesi sunar. Gıda ve içecek ambalajlarında, özellikle plastik torbalar ve film kaplamalarda sıkça tercih edilir. Ayrıca, yüksek yoğunluklu polietilen (HDPE), su ve gaz boruları, depo kapları ve dayanıklı ambalajlar için kullanılırken; düşük yoğunluklu polietilen (LDPE) genellikle esnek ambalaj malzemeleri ve torbalar için idealdir. Polietilen, aynı zamanda tarım sektöründe sulama boruları ve örtü malzemeleri olarak, inşaat sektöründe ise yalıtım malzemeleri ve zemin kaplamalarında kullanılır. Bu çok yönlü polimer, otomotiv endüstrisinde, oyuncaklarda, sağlık sektöründe ise medikal cihazlarda ve plastik torbalarda da önemli bir rol oynar, bu da onun çeşitli endüstrilerdeki önemini vurgular.
              </p>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="areasTitle">
                <h5 className='text-center'>GPPS ve Kullanım Alanları</h5>
              </div>
              <p>
              General Purpose Polystyrene (GPPS), genel amaçlı polistiren olarak bilinen bir termoplastik malzemedir ve çeşitli endüstriyel ve ticari uygulamalarda kullanılır. Şeffaflık ve sertlik özellikleri nedeniyle, özellikle ambalajlama sektöründe tercih edilir; gıda ve diğer ürünlerin ambalajlanmasında şeffaf ve estetik açıdan çekici çözümler sunar. Ayrıca, elektronik ve beyaz eşya endüstrilerinde, otomotiv sektöründe iç tasarım bileşenlerinde ve medikal ürünlerin üretiminde de yaygın olarak kullanılır. GPPS, hafifliği ve işlem kolaylığı gibi avantajları sayesinde, çeşitli ev eşyalarının üretiminde de tercih edilir. Şeffaf yapısı, ürünlerin içeriğinin görünmesini sağlar ve hijyen açısından avantajlar sunar. Ancak, bazı özel uygulamalarda darbe dayanımı ve kimyasal direnç gibi sınırlamaları bulunabilir.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
