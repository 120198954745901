import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function Products() {
  return (
    <div>
       <Helmet>
        <title>Ürünler</title>
        <meta name="description" content="Ürünler" />
        <meta name="keywords" content="Aydın Plastik" />
        <meta name="author" content="Lion Tech" />
      </Helmet>
    </div>
  );
}
